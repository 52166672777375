'use client'
import { Box, Button, Typography } from '@mui/material'
import { signIn } from 'next-auth/react'
import { FormEvent, Suspense } from 'react'
import { useSearchParams } from 'next/navigation'
import { useTheme } from '@mui/material/styles'

import { makeStyles } from '@mui/styles'

async function handleOnSearchSubmit(e: FormEvent<HTMLFormElement>) {
  e.preventDefault()

  const formData = new FormData(e.currentTarget)
  await signIn('email', {
    email: formData.get('email'),
    callbackUrl: formData.get('callback')?.toString() || '/app',
  })
}

const useStyles = makeStyles({
  loginInput: {
    '& .MuiInputBase-root': { padding: '12px', color: '#00000099' },
    '& .MuiInputLabel-root': {
      color: '#00000099',
      marginLeft: '12px',
      marginTop: '12px',
    },
    '& .MuiInputLabel-root.Mui-focused': { color: '#ffffff' },
    '& .MuiInput-underline:before': { borderBottom: '2px solid #22E77F' },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #22E77F',
    },
    '& .MuiInput-underline:after': { borderBottom: '2px solid #22E77F' },
  },
  loginButton: {
    '&.MuiButtonBase-root': {
      color: '#141A1A',
      fontWeight: 500,
      fontSize: '15px',
      border: '1px solid #000000',
      borderRadius: '4px',
    },
  },
})

function SignIn() {
  const searchParams = useSearchParams()

  const callback = searchParams.get('callback') || '/app'

  const classes = useStyles()

  const theme = useTheme()

  let statusMessage = null
  const statusType =
    searchParams.get('status') || searchParams.get('error') || ''

  switch (statusType) {
    case 'LoggedOut':
      statusMessage = (
        <Typography
          component="h2"
          variant="h3"
          sx={{ color: theme.palette.primary.main }}
        >
          You've successfully logged out!
        </Typography>
      )
      break
    case 'Error':
      statusMessage = (
        <Typography
          component="h2"
          variant="h3"
          sx={{ color: theme.palette.error.main }}
        >
          An unexpected error occurred, please try again later!
        </Typography>
      )
      break
    case 'EmailSignin':
      statusMessage = (
        <Typography
          component="h2"
          variant="h3"
          sx={{ color: theme.palette.error.main }}
        >
          Sign in failed! Please try again.
        </Typography>
      )
      break
    default:
      statusMessage = null
  }

  return (
    <Box
      sx={{
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
      }}
    >
      <Box sx={{ padding: '16px' }}>
        <Typography component="h1" variant="h1">{statusMessage}</Typography>
        <Typography component="h2" variant="h2">
          Log in with your SHIFT Platform Account.
        </Typography>
          <input type="hidden" name="callbackUrl" defaultValue={callback} />
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            size="large"
            classes={{ root: classes.loginButton }}
            sx={{
              mt: 3,
              mb: 2,
              color: '#000',
              fontWeight: 500,
              fontSize: '16px',
              padding: '12px 20px',
              lineHeight: '173.333%',
              letterSpacing: '0.46px',
              backgroundColor: '#22E77F',
            }}
            onClick={() => signIn('okta', { redirect: true, callbackUrl: '/app' })}
          >
            Log In
          </Button>
      </Box>
    </Box>
  )
}

export default function WrappedSignIn() {
  return (<Suspense fallback={<div>Loading...</div>}>
    <SignIn />
  </Suspense>)
}
